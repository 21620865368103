<template>
  <TaskDetailItemEditCard confirmation-message="Taak id werd succesvol gewijzigd" title="Bewerk taak id"
    update-store-action="tasksModule/updateFunctionalId"
    :update-store-action-payload="{ taskId: taskId, projectId: projectId, clientId: clientId, functionalId: functionalId }"
    :projectId="projectId" :clientId="clientId" :functionalId="functionalId" :promises.sync="promises"
    :successRouteLocation="{ name: routeNames.TASK_DETAIL, params: { projectId: projectId, clientId: clientId, functionalId: taskId } }">
    <template v-slot:form-fields>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field v-model="taskId" persistent-placeholder label="Id" type="text" :rules="[...getRequiredRules('Id van de taak moet ingevuld worden'),
          ...getStartsWithRules('T-', 'Id van een taak moet beginnen met T-'),
          ...getMinStringSizeRules(3, 'Id van een taak moet minimaal 1 teken bevatten'),
          ...getMaxStringSizeRules(6, 'Id van een taak mag maximaal 4 tekens bevatten')]" @input="validateTaskId" />
        </v-col>
        <v-col cols="2" class="d-flex justify-center align-center">
          <v-icon v-if="showGenerateFunctionalIdButton" @click="resetToGeneratedFunctionalId()"
            :title="`Gebruik de automatische id: ${lastFetchedFunctionalIdSuggestion}`" color="red">
            mdi-undo
          </v-icon>
        </v-col>
      </v-row>
    </template>
  </TaskDetailItemEditCard>
</template>

<script>
import TaskDetailItemEditCard from "@/views/planning/forms/TaskDetailItemEditCard.vue"
import routeNames from "@/router/RouteNames";
import { getRequiredRules, getStartsWithRules, getMaxStringSizeRules , getMinStringSizeRules } from "@/shared/utils/inputRulesUtils.js"

export default {
  name: "TaskDetailEditFunctionalId",
  props: {
    projectId: {
      type: String,
      required: true
    },
    clientId: {
      type: String,
      required: true
    },
    functionalId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      routeNames,
      taskName: null,
      taskId: null,
      promises: [
        this.$store.dispatch('tasksModule/fetchTaskBasic', { clientId: this.clientId, projectId: this.projectId, functionalId: this.functionalId })
          .then((result) => { this.taskName = result.taskName; this.taskId = result.functionalId })
      ],
      lastFetchedFunctionalIdSuggestion: null
    }
  },
  components: {
    TaskDetailItemEditCard
  },
  methods: {
    getRequiredRules,
    getStartsWithRules,
    getMinStringSizeRules,
    getMaxStringSizeRules,
    fetchGeneratedFunctionalId() {
      if (this.taskName) {
        this.$store.dispatch("tasksModule/getFunctionalIdSuggestions", { clientId: this.clientId, projectId: this.projectId, seed: this.taskName, existingId: this.functionalId })
          .then(result => {
            this.lastFetchedFunctionalIdSuggestion = result
          })
      }
    },
    resetToGeneratedFunctionalId() {
      if (this.lastFetchedFunctionalIdSuggestion) {
        this.taskId = this.lastFetchedFunctionalIdSuggestion
      } else {
        this.fetchGeneratedFunctionalId()
      }
    },
    validateTaskId(value) {
      this.$nextTick(() => {
        // Prevent external overrides by using `$nextTick` ( make sure Vue has updated the DOM)
        if (!value || value.trim() === "") {
          this.taskId = "T-";
        } else {
          value = value.toUpperCase();
          if (!value.startsWith("T-")) {
            // If the prefix is missing, enforce it
            this.taskId = "T-" + value.replace(/^T-*/, "");
          } else {
            // Otherwise, keep the valid value
            this.taskId = value;
          }
        }
      });
    }
  },
  computed: {
    showGenerateFunctionalIdButton() {
      return this.lastFetchedFunctionalIdSuggestion !== this.taskId
    }
  },
  watch: {
    taskName: {
      deep: true,
      immediate: true,
      handler() {
        if (this.taskName) {
          this.fetchGeneratedFunctionalId()
        }
      }
    },

  }
}
</script>